import React from 'react';
import LayoutTextPage from '../layout/layoutTextPage';

class NotFoundPage extends React.PureComponent<{}, {}> {
  public render() {
    return (
      <LayoutTextPage title='Oeps'>
        <div css={{width:'100vw', textAlign:'center', fontWeight:600, fontSize: "1.1em"}}>Je hebt een pagina geprobeerd te laden die niet bestaat... bel ons anders gerust even!<br/><br/>
        Onze adviseurs zijn van maandag t/m vrijdag van 09:00 – 21:00 uur en zaterdag van 10:00 – 16:00 uur beschikbaar op nummer 030 249 81 81. <br/>
        Je kunt ook een mail sturen naar service@verlengafdeling.nl</div>
      </LayoutTextPage>
    );
  }
}

export default NotFoundPage;
